.blog-wrapper-posts{
    margin-left: 10%;
    margin-right: 10%;
}
.header-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.categories{
    display: inline-flex;
padding: 7px 17px;
justify-content: center;
align-items: center;
gap: 10px;
}
.categories p{
    color: #274C5B;
text-align: center;
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.search-header input{
    position: relative;
    width: 554px;
height: 45px;
border-radius: 5px;
background: #F1F0F0;
flex-shrink: 0;
padding-left: 10%;
}
.search-header input::placeholder{
   
}
.search-icon-append{
  position: absolute;
  /* top: 10px; */
  padding-left: 10px;
  bottom: 103px;
}
.search-icon{
  position: absolute;
  border: none;
  font-size: 30px;
  background-color: transparent;
  transform: translateY(-100%);
}
.hero-cover-image{
    width: 100%;
   
    
 }
 .post-card-blog{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    margin-top: 5px;
 }
 .hero-cover-image img{
     width:100%;
 height: 338px;
 flex-shrink: 0;
 border-radius: 10px;
 object-fit: cover;
 }
 .hero-wrapper-div{
     display: flex;
     align-items: center;
     justify-content: space-between;
     margin: 10px;
 }
 .main-wrapper{
       /* margin: 10%; */
       margin-top: 10%;
       border-radius: 10px;
       background: #F1F0F0;
       width: 100%;
 }
 .details-wrapper-row img{
    width: 10%;
    height: 7%;
 display: flex;
 align-items: center;
    border-radius: 50%;
    flex-shrink: 0;
 }
 .details-wrapper-row{
    display: flex;
    align-items: center;
gap: 4%;
    width: 100%;
 }
 .post-details-colum{
display: flex;
 }

 .post-details-hero-card{
  margin: 5%;
  margin-top: 10px;

 }
 .post-details-hero-card h2{
  margin-bottom: 20%;
  color: #1B2534;
font-family: Inter;
font-size: 35px;
font-style: normal;
font-weight: 700;
line-height: 128.182%; /* 44.864px */
text-transform: capitalize;
 }
 .green-dot{
    width: 5px;
    border-radius: 50%;
height: 5px;
flex-shrink: 0;
background-color: #29A0F5;
}
.author-name-hero{
    color: #1B2534;
    color: #1B2534;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}
.post-details p{
    margin: 0;

    color: #666A82;
font-family: "Open Sans";
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.post-details {
   gap: 5px;
    
}
.card-image img{
    width: 100%;
    height: 277px;
border-radius: 5%;
object-fit: cover;
flex-shrink: 0;
}
.post-card-wrapper{
   
    width: 100%;
}
.grid-container{
    margin-top: 5%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: 48px;
  
}
.post-card-title{
    color: #1B2534;
font-family: "Open Sans";
font-size: 35px;
font-style: normal;
font-weight: 700;
line-height: 128.182%; /* 44.864px */
text-transform: capitalize;
}
.post-card-description{
    color: #606060;
font-family: "Open Sans";
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 33px */
text-transform: capitalize;
}
/* pagination css files */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .page-button {
    border: none;
    padding: 10px 15px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #f1f1f1;
    transition: background-color 0.3s;
    margin-bottom:10%;
  }
  
  .page-button.active {
    background-color: #F6F6F6;
    color: #1B2534;
  }
  
  .page-button:hover {
    background-color: #ddd;
  }
  

 /* slider css */
 .custom-carousel-container {
    position: relative;
    width: 100%;
    margin: auto;
  }
  
  .custom-carousel-item-padding {
  
  }
  
  .custom-dot-list-style {
    position: absolute;
    bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .custom-dot-list-style li {
    display: inline-block;
    margin: 0 5px;
  }
  
  .custom-dot-list-style li button {
    border: none;
    background: #ccc;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transition: background 0.3s;
  }
  
  .custom-dot-list-style li button:focus {
    outline: none;
  }
  
  .custom-dot-list-style li.slick-active button {
    background: #000;
  }

.slick-dots {
    position: absolute;
    bottom: -3px;
    left: 18%;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
@media (max-width: 768px) {

}


@media (max-width: 820px) {
  .grid-container {
    margin-top: 5%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    gap: 30px;
}
.header-wrapper {
  
  flex-direction: column-reverse;
}
.search-header{
  width: 100%;
}
.hero-wrapper-div {
 
  flex-direction: column;
 
}
.slick-dots {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.search-header input {
  width: 100%;
}
  }



@media (max-width: 320px) {

  
}
