/* footer.css */
.footerContainer {
    background-color: rgba(39, 76, 91, 1);
    height: 400px;
    /* margin-top: 15em; */
    font-family: 'Poppins', sans-serif;
    padding-top: 40px;
    /* Add other styles as needed */
}

.firstFooterContainer {
    height: 300px;
    display: flex;
    flex-direction: row;
    padding-top: 25px;
    align-items: center;
    margin-left: 10%;
    margin-right: 10%;
    border-bottom: 2px solid #fff;
    padding-bottom: 20px;
}

.linkedListContainer {
    width: 55%;
    height: 270px;
    background-color: rgba(47, 89, 106, 1);
    border-radius: 10px;
    margin-left: 2%;
}

.img-logo {
    width: 180px;
    margin-right: 10px;
}

/* .img-container {
    width: 10%;
} */

.download-container {
    margin-left: 10px;
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
    width: 23%;
    height: 270px;
    border-radius: 10px;
    padding: 02%;
    font-family: 'Poppins', sans-serif;
}

.footerLinks {
    display: flex;
    flex-direction: row;
    margin-left: 5%;
    
}

.footerLinks ul {
    list-style-type: none;
    flex-grow: 1;
   
}

.footerLinks ul li {
    margin-top: 12px;
    font-size: 15px;

}

.footerLinks ul li a, .ScrollLink {
    color: white;
    text-decoration: none;
    font-weight: 300;
    cursor: pointer;
}

.download-header {
    color: white;
    font-size: 20px;
    font-weight: 600;
}

.ios-download {
    background-color: white;
    padding: 10px;
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    border-radius: 10px;
    color: #3FB871;

}

.android-download {
    background-color: rgba(63, 184, 113, 1);
    padding: 10px;
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    border-radius: 10px;
    border: 1px solid white;
}

.second-container {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
}

.media-links {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: 10%;
    justify-content: space-between;
}

.icon-circle {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: #fff;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -10px;
}

.scroll-up {

    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: white;
    margin-left: 5%;
    margin-top: -38px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
}

.copy-rights {
    margin-left: 10%;
    color: #fff;
    font-size: 12px;
}

@media (max-width: 768px) {
    .mobile-none {
        display: none;
    }

    .footerContainer {
        height: unset;
    }
    
    .firstFooterContainer {
        height: unset;
        flex-direction: column;
        align-items: start;

    }

    .linkedListContainer {
        width: 120%;
        height: unset;
        margin-top: 3em;
        margin-bottom: 2em;
        margin-left: -1.5em;
        
    }

    .footerLinks {
        display: unset;
        flex-direction: unset;
    }
    .footerLinks ul{ 
        display: inline-table;

    }

    .download-container {
        margin-left: 0;
        width: 80%;
        height: 270px;
        align-items: start;

    }

    .second-container {
        flex-direction: column-reverse;
        padding-bottom: 3em;

    }

    .media-links {
        margin-left: 10%;
        justify-content: flex-start;
    }

    .img-container {
        margin-left: auto;
        margin-right: auto;
    }
    .copy-rights{
        margin-top: 2em;
        margin-left: 10%;
    }




}

