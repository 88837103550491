* {
  list-style-type: none;
  text-decoration: none;
}

.post-wrapper {
  margin-left: 15%;
  margin-right: 15%;
}

.post-header {
  margin-top: 8%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.search-button svg {
  /* Add any necessary styles for the SVG here */
}

.header-button {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.bg-button1 {
  display: inline-flex;
  padding: 10px 22px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #3FB871;
  font-family: Inter;
  font-size: 15px;
  font-weight: 700;
  border-radius: 5px;
  border: 1px solid #3FB871;
  background: #FFF;
}

.post-header-h3 {
  color: #274C5B;
  font-family: Inter;
  font-size: 30px;
  font-weight: 700;
}

.search-container {
  position: relative;
  display: inline-block;
  margin-top: 3%;
  width: 100%;
}

.search-input {
  padding: 10px 30px 10px 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 50%;
  background: #F1F0F0;
  color: #A5B5BB;
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
}

.search-button {
  position: absolute;
  left: 1%;
  top: 50%;
  transform: translateY(-50%);
  color: #222020;
  border: none;
}

.search-input:focus {
  outline: none;
  border-color: none;
}

.my-post {
  display: flex;
  flex-direction: column;
  margin-top: 5%;
}

.categories {
  display: inline-flex;
  padding: 7px 2px;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.list-item {
  color: #274C5B;
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  padding: 0px;
  cursor: pointer;
  transition: border-bottom-color 0.3s ease;
  text-decoration: none;
}

.list-item.active {
  border-bottom: 2px solid #274C5B;
}

.content {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.bg-button {
  display: inline-flex;
  padding: 10px 22px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: linear-gradient(103deg, #3FB871 1.36%, #3FB871 32.41%, #F7B618 67.78%, #3FB871 97.52%);
  color: #FFF;
  font-family: Inter;
  font-size: 15px;
  font-weight: 700;
  border: none;
}

.header-button a {
  background: none;
  text-decoration: none;
}

.post-card {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.card-line {
  margin-top: 15px;
  width: 100%;
  height: 1px;
  background: #DDD;
}

.post-title {
  color: #000;
  font-family: Inter !important;
  font-size: 25px;
  font-weight: 700;
}

.post-description {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
}

.post-metadata {
  display: flex;
  justify-content: space-between;
}

.card-button {
  display: flex;
  gap: 15px;
}

.edit-btn {
  color: #274C5B;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  border: none;
  background: none;
}

.delete-btn {
  color: #E84A5A;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  border: none;
  background: none;
}

.last-edited {
  color: #274C5B;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
}

.card-line1 {
  margin-top: -7px;
  width: 100%;
  height: 1px;
  background: #DDD;
}

.no-published-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}

.no-img {
  width: 100%;
  display: flex;
  justify-content: center;
}
.no-text {
  width: 100%;
  margin-top: 3%;
  text-align: center;
}

.no-p {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}
.no-drafts {
  color: #3FB871;
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}
.restore-btn {
  color: #3FB871;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  border: none;
  background-color: transparent;
}
.trash-text {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}
.post-data-wrapper {
  display: flex;
  flex-direction: column;
}
.post-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10%;
  margin-right: 10%;
  text-align: center;
}
.post-data h2 {
  color: #274C5B;
  font-family: Inter;
  font-size: 35px;
  font-weight: 700;
}
.post-data p {
  color: #274C5B;
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
}
.ce-header {
  color: #274C5B;
  font-family: Inter;
  font-size: 35px;
  font-weight: 700;
  text-align: center;
}
.post-data img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
@media (max-width: 768px) {

}
@media (max-width: 820px) {
  .post-wrapper {
    margin-left: 10%;
    margin-right: 10%;
}

.post-header-h3 {
    font-size: 25px;
}
.post-title {
  font-size: 18px;
}
.search-container {
    width: auto;
}
.search-input {
  width: 100%;
  height: 32px;
}
.categories{
  flex-wrap: nowrap;
}

.post-data h2 {
    font-size: 45px;
}

.post-data p {
    font-size: 25px;
}

.ce-header {
    font-size: 45px;
}
.list-item {

  font-size: 15px;
 
}
.restore-btn,
.last-edited ,
.edit-btn,
.delete-btn {

font-size: 12px;

}
.post-description {

font-size: 13px;

}

}

@media (max-width: 320px) {

  .post-wrapper {
    margin-left: 10%;
    margin-right: 10%;
}

.post-header-h3 {
    font-size: 25px;
}
.post-title {

  font-size: 18px;
 
}
.search-container {
    width: auto;
}

.search-input {
  width: 100%;
  height: 32px;
}
.categories{
  flex-wrap: nowrap;
}

.post-data h2 {
    font-size: 45px;
}

.post-data p {
    font-size: 25px;
}

.ce-header {
    font-size: 45px;
}
.list-item {

  font-size: 15px;
 
}
.restore-btn,
.last-edited ,
.edit-btn,
.delete-btn {

font-size: 12px;

}
.post-description {

font-size: 13px;

}
}