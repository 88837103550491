.editor-container ::placeholder{
    
}
.review-post img{
    width: 100%;
height: 423px;
object-fit: cover;
flex-shrink: 0;
}
.ce-header {
    color: #274C5B;
font-family: Inter;
font-size: 45px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-align: center;
}
.review-post p{
    color: #274C5B;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.review-post h2{
    color: #274C5B;
font-family: Inter;
font-size: 45px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-align: center;
}
.ce-paragraph {
    color: #274C5B;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.start-typing img {
    width: 100%;
height: 423px;
object-fit: cover;
flex-shrink: 0;
}
.image-tool__caption { display: none; 
}

