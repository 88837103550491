.user-wrapper {
    margin-left: 10%;
    margin-right: 10%;
   
}

.profile-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-pic {
    position: relative;
    width: 124px;
    height: 124px;

}

.profile-pic img {
    width: 100%;
    /* Ensure the image fills the container */
    height: 100%;
    /* Ensure the image fills the container */
    object-fit: cover;
    /* Preserve aspect ratio */
}

.edit-icon-container {
    position: absolute;
    bottom: -6px;
    right: -17px;
}

.edit-icon {
    width: 58px;
    /* Adjust the size of the ellipse */
    height: 58px;
    /* Adjust the size of the ellipse */
    background-color: #eaeaea;
    /* Background color of the ellipse */
    border-radius: 50%;
    /* Make it a circle */
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-icon img {
    width: 20px;
    /* Adjust the size of the edit icon */
    height: 20px;
    /* Adjust the size of the edit icon */
}

.input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 8%;
}

.item-colum {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Two columns */
    grid-gap: 20px;
}

.input-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 6%;
}
.email-submit-wrapper  .input-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0;
}
.email-submit-wrapper   p{
    color: #aa5c49;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 35px;
}
label {
    color: #274C5B;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 4%;
}

.input-with-icon {
    position: relative;
}

.input-wrapper input {
    padding: 10px 10px 10px 30px;
    /* Adjust left padding to make space for the icon */

    border-radius: 5px;
    width: 452px;
    height: 62px;
    /* Adjust the padding to accommodate the icon */
    border-radius: 5px;
    background: #F5F5F5;
    color: #274C5B;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.submit-button-email{
    display: block;
    width: 169px;
    height: 45px;
    flex-shrink: 0;
    border-radius: 5px;
    color: #FFF;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: var(--linerar-two, linear-gradient(103deg, #3FB871 1.36%, #3FB871 32.41%, #F7B618 67.78%, #3FB871 97.52%));
    padding: 0;
    border: none;
    
}
.edit-iconp {
    position: absolute;
    /* left: 10px; */
    top: 61%;
    /* transform: translateY(-50%); */
    right: 5%;
    color: #aaa;
}
.user-wrapper button{
    display: inline-flex;
    padding: 8px 33px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: #3FB871;
    color: #FFF;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border:none;
}
/* Target the password input field */
input[type="password"] {
    /* Remove default browser styles */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    /* Custom styles */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Style the reveal password button */
input[type="password"]::-webkit-outer-spin-button,
input[type="password"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    /* Remove default margin */
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

input[type="password"]::-webkit-outer-spin-button,
input[type="password"]::-webkit-inner-spin-button {
    background-color: transparent;
    /* Change the background color */
    color: #274C5B;
    /* Change the color */
    width: 10px;
    height: 10px;
    flex-shrink: 0;
    /* Change the font size */
}
.email-submit-wrapper{
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    flex-direction: column;
    justify-content:center ;
    align-items: center;
    gap: 5%;
margin-top: 10%;
}
.email-submit-wrapper input{
    padding: 10px 10px 10px 30px;
    /* Adjust left padding to make space for the icon */
margin-bottom: 4%;
    border-radius: 5px;
    width: 452px;
    height: 62px;
    /* Adjust the padding to accommodate the icon */
    border-radius: 5px;
    background: #F5F5F5;
    color: #274C5B;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.email-submit-wrapper label{
    color: #274C5B;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}
input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="password"]::placeholder {
    color: #274C5B;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.user-header{
    margin-top: 5%;
}
.user-header h3{
    color: #274C5B;
font-family: Inter;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
@media (max-width: 768px) {

}


@media (max-width: 820px) {
 
.item-colum {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
}
.input-wrapper input {
       
    width: 326px;
    height: 28px;
}

}

@media (max-width: 320px) {
    .item-colum {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;
    }
    .input-wrapper input {
       
        width: 326px;
        height: 28px;
    }
}