.create-wraper {
  display: flex;
  width: 100%;
  
}

.create-left,
.create-right {
  width: 100%;
  height: auto;
}

.create-left {
  background: #C3F0B8;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 94.8vh;
}

.create-account-form-container a {
  text-decoration: none;
}

.create-right {
  background-color: #fff;
  padding: 20px;
}

.create-right-wraper {
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 10%;
}

.create-nav {
  display: flex;
  gap: 20px;
  margin-top: 5%;
  align-items: center;
  flex-wrap: wrap;
  
}

.create-nav img {
  width: 150px;
  height: auto;
}

.create-nav button {
  width: 100px;
  height: 40px;
  border-radius: 5px;
  color: #FFF;
  font-family: Inter;
  font-size: 15px;
  font-weight: 700;
  background: linear-gradient(103deg, #3FB871 1.36%, #3FB871 32.41%, #F7B618 67.78%, #3FB871 97.52%);
  padding: 0;
  border: none;
  margin-top: 10px;
}

.create-h3 {
  margin-top: 10%;
  width: 100%;
 
}

.create-h3 h3 {
  color: #383535;
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 35px; /* 145.833% */
}

.sign-in-options {
  margin-top: 7%;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  gap: 3%;
}

.sign-in-options button {
  color: #5A5A5A;
  font-family: "PP Telegraf";
  font-size: 12px;
  font-weight: 400;
  line-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #E5E5E5;
  width: 80%;
  max-width: 300px;
  height: 45px;
  margin-bottom: 51px;
  gap: 30px;
}

.sign-in-options img {
  width: 22px;
  height: 22px;
}

.google-signin,
.facebook-signin {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.create-divide {
  display: flex;
  justify-content: center;
  margin-top: 2%;
}

.create-divide h3 {
  color: #A3A3A3;
  font-family: "PP Telegraf";
  font-size: 18px;
  font-weight: 400;
  line-height: 35px; /* 194.444% */
}

.create-account-form-container {
  width: 100%;
}

.create-account-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.form-group {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.form-group img {
  width: 20px; /* Adjust icon size */
  margin-right: 10px;
}

.form-group input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #E5E5E5;
  padding: 8px 0; /* Adjust vertical padding */
}

input:focus {
  outline: none;
}

.password-toggle {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
}

.submit-btn {
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 8px;
  border: 1px solid #B0D8DA;
  background: #3FB871;
  cursor: pointer;
  color: #FFF;
  text-align: center;
  display: block;
  width: 100%;
}

.create-option {
  margin-top: 5%;
  color: #9D9D9D;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 35px; /* 218.75% */
  text-align: center;
}

.create-option span {
  color: #3FB871;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 35px;
}

.forgot-password {
  color: #3FB871;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 35px; /* 218.75% */
  margin-bottom: 4%;
  text-align: center;
  cursor: pointer;
}

.signin-left {
  background: #6BD3AB !important;
}

.create-option a {
  text-decoration: none;
}

.create-OR{
display: none;
}



@media (max-width: 768px) {

}


@media (max-width: 820px) {
  .create-left {
  width: 88%;
  flex-direction: column;
  height: 20%;
  
   }
   .create-nav{
    display: none !important;
   }
   .create-left img {
  width: 100%;
  
   }
   .create-wraper{
flex-direction: column;
   }
   .signin-left {
   
   }
   .create-account-form-container{
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
   }
   .google-signin,
   .facebook-signin {
    gap: 18px;
   }
   .sign-in-options {
   
    flex-direction: column;
   
  }
  .create-OR{
    display: block;
  }

.create-right {
  padding: 0;
}

}

@media (max-width: 320px) {

  .create-left {
   
   }
   .signin-left {
    
   }
   .create-left img {
    width: 100%;
    
     }
     .create-right {
      
      padding: 0;
    }
  .create-account-form-container{
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
   }
   .google-signin,
   .facebook-signin {
    gap: 18px;
   }
   .sign-in-options {
   
    flex-direction: column;
   
  }
}
.error-signin{
  color:red;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 35px;
}