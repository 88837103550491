.blog-nav-wrapper {
  /* margin-top: 2%; */
  display: flex;
  justify-content: space-between;
  margin-left: 5%;
  margin-right: 5%;
  padding-bottom: 12px;
  padding-top: 12px;
  align-items: center;
}

.nav-logo {
  display: flex;
  align-items: center;
  gap: 40px;
}

.navbar-profile {
  display: flex;
}

.navbar-profile img {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}

.navbar-profile button {
  display: inline-flex;
  padding: 8px 33px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #3FB871;
  color: #FFF;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  border: none;

}
.signin-ou-button{

}

.navbar-profile span {
  color: #274C5B;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.nav-logo>button {
  display: block;
  width: 78px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 5px;
  color: #FFF;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: var(--linerar-two, linear-gradient(103deg, #3FB871 1.36%, #3FB871 32.41%, #F7B618 67.78%, #3FB871 97.52%));
  padding: 0;
  border: none;
}

.nav-logo img {
  width: 200px;
  height: 53.128px;
  flex-shrink: 0;
}

.navbar-profile {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

.profile-menu {
  width: 250px;
  height: 448px;
  flex-shrink: 0;
  position: absolute;
  top: 13%;
  right: 9%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
}

.profile-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.profile-menu ul li {
  display: flex;
  gap: 25px;
  padding: 16px 40px;
}

.profile-menu ul li p {
  margin: 0;
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.profile-menu ul li:hover {
  background-color: #f0f0f0;
}
.profile-menu button{
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-around;
  gap: 5px;
  height: 61px;
  border: none;
}
.mobile-menue{
  
 display: none;
}
@media (max-width: 768px) {

}


@media (max-width: 820px) {
 
  .navbar-profile a{
    display: none;
  }
.mobile-menue{

  display: flex;
  align-items: center;
  width: 30px;
  height: 20px;
  flex-shrink: 0;
  font-size: 12px;
}
.mobile-menue svg{
  font-size: 26px;
}
.nav-logo img {
  width: 30%;
  height: 53.128px;
  flex-shrink: 0;
}
.nav-logo {
 
  gap: 18px;
}
.user-nav-name{
  display: none !important;
}

.navbar-profile img {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
}
.nav-arrows{
  display: none !important;
}
.nav-logo>button {
  
  width: 63px;
}
.profile-menu{
  right: 0;
}
.navbar-profile span {

}
}

@media (max-width: 320px) {
  .navbar-profile a{
    display: none;
  }
  .nav-logo>button {
  
    width: 55px;
  }
  .navbar-profile span {
  
  }
}
.outer-nav-link button{
  color: #274C5B;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
  background-color: transparent;
}