

/* Loader.css */
.button-loader {
    display: flex;
    justify-content: center;
    align-items: center;
     /* Full viewport height */
  }
  
  .button-spinner {
    border: 8px solid rgba(49, 24, 24, 0.1); /* Light grey */
    border-top: 8px solid white; /* Blue */
    border-radius: 50%;
    width: 10px;
    height: 10px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  