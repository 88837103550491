.preview-wrapper{
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 8%;
}
.preview-close{
    display: flex;
    justify-content: flex-end;
}
.close-preview-p{
    color: #000;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: 0;
}
.preview-close-body svg{
    width: 30px;
height: 30px;
}
.preview-close-body {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}
.preview-header{
    display: flex;
    flex-direction: column;
   
    width: 530px;
}
.preview-cover{
    width: 100%;
height: 279px;
flex-shrink: 0;
background: #F5F5F5;
display: flex;
justify-content: center;
align-items: center;
margin-top: 15px;
}
.preview-h3{
    color: #274C5B;
font-family: Inter;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-bottom: 8%;
}
.preview-header-p{
    color: #274C5B;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.section-preview{
    width: 100%;
    display: flex;
    gap: 5%;
}
.preview-left{
    width: 50%;
}
.preview-right{
    width: 50%;
    display: flex;
    flex-direction: column;
}
.preview-right-p{
    color: #274C5B;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.preview-right-p span{
    color: #274C5B;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.input-container-preview {
    width: 100%;
    margin-top: 6%;
    margin-bottom: 20px; /* Add margin for spacing */
  }
  
  .input-container-preview input {
    border: none; /* Remove the default border */
    border-bottom: 1px solid  #BDBDBD; /* Add border bottom */
    outline: none; /* Remove the outline */
    padding: 5px 0; /* Add padding for text */
    width: 100%; /* Make the input field full width */
  }
  
  /* Style for the placeholder text */
  .input-container-preview input::placeholder {
    color: #274C5B;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal; /* Change the placeholder color */
  }
  .preview-publish-now{
    display: flex;
    gap: 15px;
    margin-top: 5%;
    align-items: center;
  }
  .preview-publish-now button{
    display: inline-flex;
padding: 8px 33px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 5px;
background: #3FB871;
color: #FFF;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
border:none;
  }
  .preview-schedule{
    color: #274C5B;
text-align: center;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: 0;
cursor: pointer;
  }
  .preview-content-post{
    margin-top: 8%;
  }
  .post-preview-post-header{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .post-preview-post-header h3{
    color: #274C5B;
font-family: Inter;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
  }
  /* Style for the focus state */
  .input-container-preview input:focus {
   /* Change border bottom color on focus */
  }
  .input-container-preview-right {
   /* Add margin for spacing */
  }

  .input-container-preview-right label {
    color: #274C5B;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
    margin-bottom: 2%;/* Optionally make labels bold */
  }

  .input-container-preview-right input[type="text"] {
    border: none; /* Remove default border */
    background: #FAFAFA; /* Add bottom border */
    padding: 5px 0; /* Add padding for space around text */
    width: 100%;
    height: 83px;
    flex-shrink: 0; 
    /* Make input full width */
   
  }

  /* Style placeholder text */
  .input-container-preview-right input[type="text"]::placeholder {
    margin-left: 2%;
  }

  /* Style on focus */
  .input-container-preview-right input[type="text"]:focus {
    outline: none; /* Remove default focus outline */
    border-bottom-color: #007bff; /* Change bottom border color on focus */
  }
 
  .text-div h3{
    color: #274C5B;
font-family: Inter;
font-size: 45px;
font-style: normal;
font-weight: 700;
line-height: normal;
    width: 100%;
    text-align: center;
    margin-bottom: 10%;
  }
.text-div{
  margin-left: 20%;
  margin-right: 20%;

}
.input-container-preview-right input{
  color: #274C5B;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.input-container-preview input{
  color: #274C5B;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.text-div  h2{
  color: #274C5B;
  font-family: Inter;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}
.text-div  h1{
  color: #274C5B;
  font-family: Inter;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}
.text-div p{
  color: #274C5B;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 768px) {

}


@media (max-width: 820px) {
 
  .preview-wrapper {
    margin-left: 5%;
    margin-right: 5%;
}

.section-preview {
    flex-wrap: nowrap;
}
.section-preview {
 flex-direction: column;
}

.preview-left,
.preview-right {
    width: 100%;
}

.preview-h3 {
    font-size: 20px;
}
.post-preview-post-header h3 {
 
  font-size: 20px;
  
}
.preview-publish-now button {

font-size: 12px;

}
.preview-right-p span {

font-size: 15px;

}
.preview-header-p,
.preview-right-p {
    font-size: 12px;
}

.text-div h3 {
    font-size: 50px;
}
.text-div img{
  width: 100%;
  
}


.text-div h2 {
    font-size: 28px;
}

.text-div p {
    font-size: 24px;
    line-height: 36px;
}
.preview-header {
  width: 100%;
}
}

@media (max-width: 320px) {

  .preview-wrapper {
    margin-left: 10%;
    margin-right: 10%;
}

.section-preview {
    flex-wrap: nowrap;
}
.section-preview {
 flex-direction: column;
}

.preview-left,
.preview-right {
    width: 100%;
}

.preview-h3 {
    font-size: 20px;
}
.post-preview-post-header h3 {
 
  font-size: 20px;
  
}
.preview-publish-now button {

font-size: 12px;

}
.preview-right-p span {

font-size: 15px;

}
.preview-header-p,
.preview-right-p {
    font-size: 12px;
}

.text-div h3 {
    font-size: 50px;
}

.text-div h2 {
    font-size: 28px;
}

.text-div p {
    font-size: 24px;
    line-height: 36px;
}
}