.post-wrapper {
    margin-bottom: 10%;

}

.post-main-wrapper {
    display: flex;
    gap: 1.8%;
}

.main {
    width: 60%;
}

.side {
    width: 40%;
}
.post-cover-image {
 
}
.social-links{
    display: flex;
    align-items: center;
    gap: 20px;
}
.post-cover-image img{
    width: 100%;
    height: 52vh;
    flex-shrink: 0;
    object-fit: fill;
    border-radius: 18px;
}

.post-title {

    color: #1B2534;
    font-family: "Open Sans";
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 128.182%;
    /* 44.864px */
    text-transform: capitalize;

}

.blog-categories-h3 {
    color: #1B2534;
    font-family: "Open Sans";
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 128.182%;
    /* 38.454px */
    text-transform: capitalize;
    margin: 0;
}

.categories-items {

    display: flex;
    align-items: center;
    justify-content: space-between;

}

.categories-items p,
span {
    color: #606060;
    font-family: "Open Sans";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 250%;
    /* 55px */
    text-transform: capitalize;
    margin: 0;
}

.divider {
    width: 100%;
    height: 1px;
    background: #F4F2F0;
}
.card-tag-wrapper h3{
    color: #1B2534;
font-family: "Open Sans";
font-size: 30px;
font-style: normal;
font-weight: 600;
line-height: 128.182%; /* 38.454px */
text-transform: capitalize;
}
.tag-card{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
height: 80px;
flex-shrink: 0;
border-radius: 20px;
border: 1px solid #D3D3D3;
background: #FFF;
}
.social-links-div{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    /* gap: 10px; */
    border-radius: 20px;
    background: #1B2534;
    color: #FFF;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 66px;
    margin-top: 8%;
}
.recent-card-wrapper{
    display: flex;
width: 449px;

align-items: center;
gap: 31px;
}
.recent-card-wrapper img{
    width: 20%;
height: 20%;
border-radius: 10%;
flex-shrink: 0;
}
.recent-card-wrapper p{
    color: #666A82;
font-family: "Open Sans";
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.recent-card-wrapper h5{
    color: #1B2534;
font-family: "Open Sans";
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.post-details-recent{
    display: flex;
    align-items: center;
    gap: 10px;
    color: #666A82;
font-family: "Open Sans";
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.green-dot{
    width: 5px;
    border-radius: 50%;
height: 5px;
flex-shrink: 0;
background-color: #29A0F5;
}
.share-container{
    display: flex;
width: 100%;
align-items: center;
gap: 18px;
flex-shrink: 0;
justify-content: space-between;
}
.share-container img{
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 50%;
}
.author-profile-pic{
    display: flex;
    align-items: center;
    gap: 18px;
}
.author-profile-pic p{
    color: #1B2534;
font-family: "Open Sans";
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.post-details{
    display: flex;
    align-items: center;
    gap: 20px;
    
}
.side-bar{
width: 100%;
}
.side-search-bar{
    width: 100%;
}
.side-search-bar input{
    width: 90%;
    height: 40px;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1px solid #D3D3D3;
    background: #FFF;
    padding-left: 40px;
}
.comment-main-row{
    display: flex;
    width: 100%;
    gap: 10px;
}
.submit-wrapper {
    display: flex;
    justify-content: start;
    margin-top: 20px;
  }
  
  .comment-submit {
    display: flex;
    align-items: center;
    border-radius: 20px;
background: #1B2534;
    justify-content: space-between;
    border: none;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  
    gap: 11px;
  }
  
  .comment-submit p {
   
  }
  .post-a-comment{
    color: var(--Color, #FFF);

    text-align: center;
    font-family: "Open Sans";
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }
  
  .icon {
    width: 14px;
    height: 12px;
    margin-left: 10px;
    color: #29A0F5; /* Set the icon color to green */
  }
  
  .comment-submit:hover {
    background-color: #0056b3; /* Change this to your desired hover background color */
  }
  
.side-search-bar input::placeholder{
    color: #606060;
font-family: "Open Sans";
font-size: 20px;
font-style: normal;
padding-left: 20px;
font-weight: 400;
line-height: normal;
text-transform: capitalize;
margin-left: 100px;
}
/* comment section */
.comment-section-wp-row{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

}


.save-button{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}
.comment-section-wp-colum{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;

}
.comment-section-wp-row input{
height: 80px;
border-radius: 20px;
border: 1px solid #D3D3D3;
background: #FFF;
flex-shrink: 0;
}
.comment-section-wp-colum input{
height: 80px;
border-radius: 20px;
border: 1px solid #D3D3D3;
background: #FFF;
flex-shrink: 0;
padding-left: 30px;
color: #606060;
font-family: "Open Sans";
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: lowercase;
}

.comment-section-wp-colum textarea{
    
    height: 274px;
    border-radius: 20px;
border: 1px solid #D3D3D3;
background: #FFF;
    flex-shrink: 0;
    color: #606060;
font-family: "Open Sans";
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: lowercase;
padding: 10%;
;
}
.social-icons{
    color: #1B2534;
text-align: center;
font-family: "Font Awesome 5 Brands";
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 120%; /* 21.6px */
letter-spacing: 0.99px;
text-transform: uppercase;
display: flex;
align-items: center;
gap: 10px;
}
 a{
    color: #FFF;
}

.text-div h3{
    color: #274C5B;
font-family: Inter;
font-size: 45px;
font-style: normal;
font-weight: 700;
line-height: normal;
    width: 100%;
    text-align: center;
    margin-bottom: 10%;
  }
.text-div{
  

}
.text-div  h2{
    color: #274C5B;
    font-family: Inter;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
  }
  .text-div  h1{
    color: #274C5B;
    font-family: Inter;
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
  }
  .text-div p{
    color: #274C5B;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }



  @media (max-width: 768px) {

  }
  
  
  @media (max-width: 820px) {
    .main {
        width: 100%;
    }
    .post-main-wrapper {
     
        flex-direction: column;
    }
    .post-title {
    
        font-size: 25px;
       
    }
    
    }
  
  
  
  @media (max-width: 320px) {
  
    
  }
