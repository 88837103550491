.blog {
    background-color: #F5F5F5;

}

.post-wrapper {
   
}

.publish-btn {
    display: inline-flex;
    padding: 8px 33px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #fff;
    border-radius: 5px;
    background: #3FB871;
    border: none;
}

.post-publish {

    display: flex;
    /* width: 100%; */
    flex-shrink: 0;
    background: #FFF;
    justify-items: center;
    justify-content: space-between;
    align-items: center;
    margin-left: 10%;
    margin-right: 10%;
}
.post-publish-wrapper{
    background: #FFF;
    width: 100%;
}
.post-edit {

    display: flex;
    margin-left: 10%;
    margin-right: 10%;
    gap: 10px;
    margin-top: 8px;
}

.post-text-edit {
    display: flex;
    flex-direction: column;
    width: 72.2222222%;
    height: 1055px;
    background: #FFF;
    flex-shrink: 0;
}

.post-cover-photo {
    margin-left: 9%;
    margin-right: 9%;
    margin-top: 4%;

    height: 308px;
    flex-shrink: 0;
    background: #F5F5F5;
}
.nav-wrapper{
    width: 100%;
    margin-bottom: 7px;
    background: #FFF;
}
.post-cover-photo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.post-edit-cover-upload {

    width: 267px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 54px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 3px solid #000;
    opacity: 0.1;
    background: #D9D9D9;
    margin-top: 30px;
    cursor: pointer;
}

.upload-cover-p {
    
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    margin: 0;
    cursor: pointer;
   border: none;
}

.tooltipblog {
    width: 100%;
}

.cover-photo-h2 {
    color: #767474;
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.cover-photo-h3 {
    color: #767474;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.post-side-bar {
    width: 28.88666%;
    height: 1051px;
    background: #FFF;
    flex-shrink: 0;
}

.post-settings {
    display: flex;
    width: 100%;
    height: 69px;
    background: #F1FAFF;

    flex-shrink: 0;
}

.post-settings-h3 {
    display: flex;
    align-items: center;
    padding-left: 11px;
}

.post-settings-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10%;
    transition: all;

}

.side-settings {
    margin: 0 30px 0 30px;
}

.selected-time p {}

.post-setting-label {
    color: var(--sec, #274C5B);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.post-settings-arrow {
    width: 13.7px;
    height: 7.85px;
    flex-shrink: 0;
    color: #134563;
}

.divider {
    width: 100%;
    height: 1px;
    background: #D2CFCF;
}

input[type="radio"] {
    width: 20px;
    height: 20px;
    background-color: #3FB871;
    border: 2px solid;

}

.post-wrapper1 label {
    color: var(--sec, #274C5B);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
  
    gap: 25px;
   margin: 0;

}

.post-radio {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 12px;
    gap: 12px;
}

#label-post {
    accent-color: green;
}

.display-shedule {
    display: flex;
    justify-content: space-between;
}

.selected-date-time {}

.categories-ul {
    width: 100%;
    margin: 0;
    padding: 0;
    transition: all;
    margin-top: 6%;
}

.categories-ul li {
    display: flex;
    gap: 22px;
    align-items: center;
    width: 100%;
    margin-top: 3%;
}

.divider-display {
    height: 1px;
    background: #D2CFCF;
}

.tooltipblog img {
    width: 80%;
    height: 102px;
    margin-left: -2%;

}

.post-edit-content {
    background-color: #fff;
}
.bog-title{
   
    overflow: visible;
}
.bog-title textarea {
    color: #A6A6A6;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 10%;
    margin-left: -5%;
    width: 100%;
    border: none;
    overflow: auto;
    outline: none;
text-align: center;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    resize: none; 
}
.post-edit-content textarea:focus{
border: none;
}
.bog-title textarea::placeholder{
   color: #A6A6A6 !important;
    font-family: Inter !important;
    font-size: 40px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
   
}
.start-typing {
    display: flex;
    /* gap: 5%; */
    /* width: 100%; */
    /* align-items: center; */
    /* flex-direction: column; */
    width: 100%;
  
}
#toggle-edit-btn{
    margin-bottom: 10%;
}
.start-typing img {
    width: 40px;
    height: 40px;

    flex-shrink: 0;
}

.start-div {
   
   width: 100%;
   height: auto;

}
.author-name{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
height: 38px;
flex-shrink: 0;
border-radius: 5px;
border: 1px solid #000;
background: #FFF;
margin-top: 10%;
}
.author-name p{
    color: #000;
font-family: Inter;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: 0;
}
.uploaded-cover-photo{
   

}
.categories-ul-li{
    display: flex;
    align-items: center;
    gap: 12px;
}

@media (max-width: 768px) {

}


@media (max-width: 820px) {
 
    .post-side-bar{
width: 100%;
    }
    .post-cover-photo {
       width: 100%;
       margin-left: 0;
    }
    .post-text-edit {
        width: 100%;
    }
    .post-edit {
flex-direction: column;
        margin-left: 5%;
        margin-right: 5%;
       
    }
    .cover-photo-h3 {
       
        font-size: 11px;
        
    }
    .cover-photo-h2 {
        
        font-size: 24px;
       
    }
}

@media (max-width: 320px) {

    .post-side-bar{
       
    }
    .post-cover-photo {
       width: 100%;
    }
    .post-text-edit {
        width: 100%;
    }
    .post-edit {
     
        margin-left: 5%;
        margin-right: 5%;
       
    }
}