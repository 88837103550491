body{
    background-color: #fff;
}
.stat-wrapper{

    margin-left: 10%;
    margin-right: 10%;

}
.stat-header{
    width: 100%;
    margin-top: 5%;
    display: flex;
    justify-content: space-between;
}
.stat-h3{
    color: #274C5B;
font-family: Inter;
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.stat-header button{
    display: inline-flex;
padding: 10px 22px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 5px;
border: 1px solid #3FB871;
background: #FFF;
color: #3FB871;
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
/* styles.css */

.stat-parent {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    grid-gap: 20px; /* Gap between grid items */
    margin-top: 8%;
  }
  
  .stat-item {
    
    padding: 20px; /* Padding */
    border-radius: 8px; /* Rounded corners */
    text-align: center; /* Center text */
  }
  
  .stat-item h3 {
    color: #274C5B;
    font-family: Inter;
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .stat-item p {
    color: #274C5B;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  